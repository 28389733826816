import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FDF9F2',
      neutralLight3: '#E5DFD8',
      neutralLight2: '#D8BFA4',
      neutralLight1: '#695850',
      neutralDark2: '#818181',
      neutralDark1: '#C3C3C3',
      primaryDark: '#1E1815',
      primaryLight: '#B17F49',
      danger: '#F23030',
    },
  },
  fontFamily: {
    headingBig: "'minion-pro-display', serif",
    heading: "'minion-pro', serif",
    paragraph: "'montserrat', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  label: {
    medium: css`
      color: ${theme.colors.variants.primaryLight};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      letter-spacing: 0.1rem;
      line-height: 1.75rem;
      font-weight: 500;
      font-style: italic;
    `,
    small: css`
      color: ${theme.colors.variants.primaryLight};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 0.75rem;
      letter-spacing: 0.2em;
      line-height: 1.25rem;
      text-transform: uppercase;
      font-weight: 600;
    `,
  },
  title: {
    xl: css`
      font-family: ${theme.fontFamily.headingBig};
      font-size: 5.75rem;
      line-height: 6.125rem;

      @media (max-width: 1199px) {
        font-size: 3.25rem;
        line-height: 4rem;
      }

      @media (max-width: 767px) {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    `,
    large: css`
      font-family: ${theme.fontFamily.headingBig};
      font-size: 3.875rem;
      line-height: 4.875rem;

      @media (max-width: 1199px) {
        font-size: 2.5rem;
        line-height: 3.2rem;
      }

      @media (max-width: 767px) {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    `,
    medium: css`
      font-family: ${theme.fontFamily.headingBig};
      font-size: 3.5rem;
      line-height: 4.25rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.75rem;
      }

      @media (max-width: 574px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    `,
    small: css`
      font-family: ${theme.fontFamily.heading};
      font-size: 2rem;
      line-height: 2.375rem;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralLight1};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.875rem;
    line-height: 1.5rem;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.neutralLight4};
    color: ${theme.colors.variants.primaryDark};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.primaryDark, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    background-color: ${theme.colors.variants.neutralLight2} !important;
    color: ${theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.variants.neutralLight1};
    color: ${theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${theme.colors.variants.neutralLight1} !important;
    color: ${theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${theme.colors.variants.neutralLight2};
    }
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper {
    input {
      font-family: ${theme.fontFamily.heading};
      color: ${theme.colors.variants.primaryDark};
      line-height: 2.375rem;
      font-size: 2rem;

      &.react-datepicker-ignore-onclickoutside {
        opacity: 0.6;
      }

      @media (max-width: 1400px) {
        font-size: 1.5rem;
        line-height: 1.875rem;
      }
    }
  }

  .react-numeric-input {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
    input {
      width: 2.5rem;
      caret-color: transparent;
      color: ${theme.colors.variants.primaryDark};
      font-family: ${theme.fontFamily.heading};
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin-left: auto;
      pointer-events: none;
    }
    b {
      width: 1.8125rem;
      height: 1.8125rem;
      background: ${theme.colors.variants.neutralLight2};
      cursor: pointer;
      position: relative;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        background: ${theme.colors.variants.neutralLight1};

        &:before,
        &:after {
          background: ${theme.colors.variants.neutralLight4};
        }
      }
      &:before,
      &:after {
        content: '';
        background: ${theme.colors.variants.neutralLight4};
        position: absolute;
        transition: 0.1s ease-out;
      }
      &:nth-of-type(1) {
        &:before {
          width: 0.875rem;
          height: 0.125rem;
          top: 0.8125rem;
          left: 0.4375rem;
        }
        &:after {
          width: 0.125rem;
          height: 0.875rem;
          top: 0.4375rem;
          left: 0.8125rem;
        }
      }
      &:last-of-type {
        margin-left: 0.125rem;
        &:before {
          width: 0.875rem;
          height: 0.125rem;
          top: 0.8125rem;
          left: 0.5rem;
        }
      }
    }
  }
`

export type Numeric = typeof numeric

export const numeric = css`
  .react-numeric-input {
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 2.25rem;
      caret-color: transparent;
      color: ${theme.colors.variants.primaryDark};
      font-family: ${theme.fontFamily.heading};
      font-size: 1rem;
      font-weight: 700;
      order: 2;
      pointer-events: none;
      text-align: center;
    }
    b {
      width: 1.8125rem;
      height: 1.8125rem;
      background: ${theme.colors.variants.primaryDark};
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      text-align: center;
      transition: 0.1s ease-out;
      &:hover {
        background: ${theme.colors.variants.neutralDark2};
      }
      &:before,
      &:after {
        content: '';
        background: ${theme.colors.variants.neutralLight5};
        position: absolute;
        transition: 0.1s ease-out;
      }
      &:nth-of-type(1) {
        order: 2;
        &:before {
          width: 0.75rem;
          height: 0.0625rem;
          top: 0.875rem;
          left: 0.5rem;
        }
        &:after {
          width: 0.0625rem;
          height: 0.75rem;
          top: 0.5rem;
          left: 0.875rem;
        }
      }
      &:last-of-type {
        &:before {
          width: 0.75rem;
          height: 0.0625rem;
          top: 0.875rem;
          left: 0.5rem;
        }
      }
    }
  }
`

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
